import React, { Fragment, useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import * as Dropzone from 'react-dropzone/dist/index'
import heic2any from 'heic2any'

import {
    createEntityService,
    deleteEntityService,
} from 'services/entity.service'

import { formatResponseData } from 'utils/jsonApiFormatters'
import axios, { POST } from 'utils/axiosClient'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import { AlertContext } from 'contexts/AlertContext'

import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    ALERT_TYPES,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import Icon from 'components/Icon'
import Note from 'components/Note'
import NoData from 'components/NoData'
import DateCell from 'components/table/tableCells/DateCell'
import { Table } from 'components/table'
import FileCell from 'components/table/tableCells/FileCell'
import FileModal from 'components/FileModal'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import TabHeading from 'screens/diver/profile/diverInformation/components/TabHeading'

const TABLE_HEADER = [
    {
        key: 'file.originalName',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: true,
        sortKey: 'name',
        CellComponent: FileCell,
    },
    {
        key: 'createdDate',
        title: 'table.header.dateUploaded',
        showOnSmallScreens: true,
        sortable: true,
        CellComponent: DateCell,
    },
]

const UploadedDiveRecords = ({ myProfile, userID, user }) => {
    const t = useTranslate()

    const queryParams = useLocation().search

    const { setAlert } = useContext(AlertContext)

    const [file, setFile] = useState(null)
    const [downloadFile, setDownloadFile] = useState(false)

    const {
        data: uploadedDiveRecords,
        meta,
        isLoading,
        fetchData,
    } = useFetchDataByQueryParams(
        ENTITIES.UPLOADED_DIVE_RECORDS,
        {
            'creator.id': userID,
            include: 'file',
        },
        false,
        true,
        true
    )

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id].includes(status)

    const onDrop = useCallback(
        async (acceptedFiles) => {
            try {
                let uploadedFiles = []
                for (const element of acceptedFiles) {
                    const formData = new FormData()
                    let acceptedData = element
                    let fileName = element.name

                    if (['image/heic', 'image/heif'].includes(element.type)) {
                        // Convert .heic image to .jpg format
                        let convertedImage = await heic2any({
                            blob: element,
                            toType: 'image/jpeg', // Convert to JPEG format
                        })
                        acceptedData = convertedImage
                        fileName = element.name.replace('.heic', '.jpeg')
                    }

                    formData.append('file', acceptedData, fileName)

                    const { data } = await axios(
                        POST,
                        `/${ENTITIES.FILE}/${ENTITIES.UPLOAD_DIVE_RECORD}`,
                        formData
                    )

                    const { data: uploadedFile } = formatResponseData(data)
                    uploadedFiles.push(uploadedFile)
                }

                await createEntityService(
                    ENTITIES.UPLOAD_DIVE_RECORDS,
                    {
                        files: uploadedFiles.map((item) => item.id),
                    },
                    false
                )

                setAlert(
                    t('message.filesUploadedSuccessfully'),
                    ALERT_TYPES.SUCCESS
                )
                fetchData()
            } catch (error) {
                if (
                    error?.response?.data?.transMessage ===
                    'Unsupported file extension'
                ) {
                    setAlert(
                        t('server.error.unsupportedFileExtension'),
                        ALERT_TYPES.ERROR
                    )
                } else {
                    setAlert(error, ALERT_TYPES.ERROR, t)
                }
            }
        },
        [queryParams]
    )

    const { getRootProps, getInputProps, isDragActive } = Dropzone.useDropzone({
        onDrop,
        noDrag: true,
    })

    const handleView = (item) => {
        setFile(item.file)
    }

    const handleDownload = (item) => {
        setFile(item.file)
        setDownloadFile(true)
    }

    const handleDelete = async ({ id, name }) => {
        try {
            await deleteEntityService(ENTITIES.UPLOADED_DIVE_RECORDS, id)
            setAlert(
                `${t('general.file')} "${name}" ${t(
                    'message.deletedSuccessfully'
                )}`,
                ALERT_TYPES.SUCCESS
            )
            fetchData({
                page:
                    uploadedDiveRecords.length === 1 && meta.currentPage > 1
                        ? meta.currentPage - 1
                        : meta.currentPage,
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleOpenFile = () => {
        setFile(null)
        setDownloadFile(false)
    }

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.DIVE_RECORDS}
                title="general.uploadedDiveRecords"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profileId={userID}
                checkAccessControl={fetchData}
                profile={user}
                requestTime={meta.dataAccessRequestTime}
                grantedWithoutFilesMessage="general.cannotAccessUploadedDiveRecords"
            />
        )
    }

    return (
        <div className="_wr profileWrapper">
            <div className={myProfile ? '-mb20' : ''}>
                <TabHeading
                    tabTitle="general.uploadedDiveRecords"
                    tab={ROUTES.DIVE_RECORDS}
                    myProfile={myProfile}
                />
                {myProfile && (
                    <Fragment>
                        <div className="-mt20 -mb20 fullWidth">
                            <Note
                                note={
                                    !uploadedDiveRecords.length
                                        ? 'message.uploadedDiveRecords'
                                        : 'message.ocrComingSoon'
                                }
                            />
                        </div>
                        <div className="m-addAndUpload">
                            <div className="m-addAndUpload__box">
                                <div {...getRootProps()} className="fullWidth">
                                    <div className="a-file">
                                        <input {...getInputProps()} />
                                        <div className="icon">
                                            <Icon
                                                name={ICONS.UPLOAD}
                                                size={ICON_SIZE.SIZE20}
                                                color={COLORS.LIGHT_BLUE}
                                            />
                                        </div>
                                        <p className="a-mediumText">
                                            {t(
                                                'form.placeholder.uploadDiveRecords'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="-mt20 a-captionsTextRegular a-lightText justify-center">
                            {t('general.supportedFormats')}
                        </span>
                    </Fragment>
                )}
            </div>
            {!!uploadedDiveRecords.length && (
                <Table
                    headerItems={TABLE_HEADER}
                    data={uploadedDiveRecords}
                    totalItems={meta.totalItems}
                    isLoading={isLoading}
                    rowDropdownActions={[
                        {
                            handleAction: handleView,
                            icon: ICONS.HIDE_PASSWORD,
                            label: 'button.view',
                            btnClass: BUTTON_STATUS.TERTIARY,
                        },
                        {
                            handleAction: handleDownload,
                            icon: ICONS.DOWNLOAD,
                            label: 'button.download',
                            btnClass: BUTTON_STATUS.TERTIARY,
                        },
                        myProfile && {
                            handleAction: handleDelete,
                            icon: ICONS.DELETE,
                            iconColor: 'red',
                            label: 'button.delete',
                            title: 'general.deleteFile',
                            confirmationMessage: 'message.areYouSureDeleteFile',
                            btnClass: BUTTON_STATUS.TERTIARY,
                            textColor: '-redText',
                        },
                    ].filter((item) => item)}
                />
            )}
            {!uploadedDiveRecords.length && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={
                        myProfile
                            ? t('general.noDataResults')
                            : t('general.noDataYet')
                    }
                    description={
                        myProfile
                            ? t('general.noUploadedDiveRecordsResultsDesc')
                            : t('general.nothingHereRightNow')
                    }
                />
            )}
            {!uploadedDiveRecords.length && renderLoader(isLoading, true)}
            {file && (
                <FileModal
                    file={file}
                    uploaded={[file]}
                    setOpenFile={handleOpenFile}
                    onlyDownload={downloadFile}
                />
            )}
        </div>
    )
}

export default UploadedDiveRecords
