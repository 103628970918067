import COLORS from './colors'
import ENTITIES from './entities'
import ICONS from './icons'

export const DEFAULT_LANGUAGE = 'en'

export const SCREEN_WIDTH = {
    EXTRA_EXTRA_LARGE: 1300,
    EXTRA_LARGE: 1200,
    LARGE: 992,
    MEDIUM: 768,
    SMALLER_MEDIUM: 576,
    SMALL: 480,
}

export const ROLES = {
    ROLE_DIVER: {
        id: 1,
        name: 'ROLE_DIVER',
        label: 'Diver',
        entityType: ENTITIES.ROLE,
    },
    ROLE_DIVING_CONTRACTOR: {
        id: 2,
        name: 'ROLE_DIVING_CONTRACTOR',
        label: 'Diving contractor',
        entityType: ENTITIES.ROLE,
    },
    ROLE_COMPANY_ADMIN: {
        id: 3,
        name: 'ROLE_COMPANY_ADMIN',
        label: 'Company admin',
        entityType: ENTITIES.ROLE,
    },
    ROLE_SUPER_ADMIN: {
        id: 4,
        name: 'ROLE_SUPER_ADMIN',
        label: 'Super admin',
        entityType: ENTITIES.ROLE,
    },
    ROLE_CLIENT_ORGANIZATION_ADMIN: {
        id: 5,
        name: 'ROLE_CLIENT_ORGANIZATION_ADMIN',
        label: 'Company',
        entityType: ENTITIES.ROLE,
    },
}

export const DIVER_POSITION_CATEGORIES = {
    DIVING_PERSONNEL: { code: 'DIVING_PERSONNEL', id: 1 },
    MARINE_MOU_PERSONNEL: { code: 'MARINE_MOU_PERSONNEL', id: 2 },
    ROV_PERSONNEL: { code: 'ROV_PERSONNEL', id: 3 },
    SURVEY_PERSONNEL: { code: 'SURVEY_PERSONNEL', id: 4 },
    PROJECT_MISSION_PERSONNEL: { code: 'PROJECT_MISSION_PERSONNEL', id: 5 },
    PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL: {
        code: 'PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL',
        id: 6,
    },
}

export const DIVING_PERSONNEL_POSITIONS = {
    LIFE_SUPPORT_SUPERVISOR: { code: 'LIFE_SUPPORT_SUPERVISOR' },
    DIVE_SUPERINTENDENT: { code: 'DIVE_SUPERINTENDENT' },
    SURFACE_DIVER: { code: 'SURFACE_DIVER' },
    OFFSHORE_MANAGER_DIVING: { code: 'OFFSHORE_MANAGER_DIVING' },
    DIVE_SUPERVISOR: { code: 'DIVE_SUPERVISOR' },
    DIVE_EQUIPMENT_TECHNICIAN: { code: 'DIVE_EQUIPMENT_TECHNICIAN' },
    LIFE_SUPPORT_TECHNICIAN: { code: 'LIFE_SUPPORT_TECHNICIAN' },
    BELL_SAT_DIVER: { code: 'BELL_SAT_DIVER' },
    MEDIC: { code: 'MEDIC' },
}

export const DIVE_RECORD_TYPE = {
    DIVER_RECORD: {
        id: 1,
        name: 'Diver',
    },
    SUPERVISOR_RECORD: {
        id: 2,
        name: 'Diving supervisor',
    },
    DIVER_MEDIC_RECORD: {
        id: 3,
        name: 'Diver medic',
    },
}

export const ALL_ROLES = [
    ROLES.ROLE_DIVER,
    ROLES.ROLE_DIVING_CONTRACTOR,
    ROLES.ROLE_COMPANY_ADMIN,
    ROLES.ROLE_SUPER_ADMIN,
    ROLES.ROLE_CLIENT_ORGANIZATION_ADMIN,
]

export const COMPANY_ROLES = [
    ROLES.ROLE_DIVING_CONTRACTOR,
    ROLES.ROLE_COMPANY_ADMIN,
    ROLES.ROLE_CLIENT_ORGANIZATION_ADMIN,
]

export const ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    NOTIFICATION: 'notification',
}

export const ICON_SIZE = {
    SIZE10: 10,
    SIZE12: 12,
    SIZE16: 16,
    SIZE18: 18,
    SIZE20: 20,
    SIZE22: 22,
    SIZE24: 24,
    SIZE32: 32,
    SIZE36: 36,
    SIZE48: 48,
    SIZE50: 50,
    SIZE60: 60,
    SIZE80: 80,
}

export const ALERT_POSITIONS = {
    CENTER: 'center',
    RIGHT: 'right',
}

export const INPUT_FILED_TYPE = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    FILE: 'file',
    CHECKBOX: 'checkbox',
    NUMBER: 'number',
}

export const SELECT_VALUE_TYPE = {
    STRING: 'string',
    OBJECT: 'object',
}

export const BUTTON_TYPE = {
    BUTTON: 'button',
    SUBMIT: 'submit',
    RESET: 'reset',
}

export const BUTTON_STATUS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    QUATERNARY: 'quaternary',
    DANGER: 'danger',
    ICON_BUTTON: 'icon_button',
    COVER_BUTTON: 'cover_button',
    DANGER_RED: 'dangerRed',
}

export const BUTTON_SIZE = {
    LARGE: '50',
    MEDIUM: '40',
    SMALL: '35',
    COVER: '30',
    XSMALL: '21',
}

export const SORT_TYPES = {
    ASC: 1,
    DESC: -1,
}

export const TABLE_FILTER_TYPE = {
    TEXT: 'text',
    DATE_TIME: 'date-time',
    SELECT: 'select',
    CHECKBOX: 'checkbox',
}

export const ICON_POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
}

export const LOGO_TYPE = {
    LIGHT: 'light',
    DARK: 'dark',
}

export const USER_REGISTRATION_TYPE = {
    DIVER: 'Diver',
    DIVING_CONTRACTOR: 'Diving contractor',
}

export const FILE_UPLOAD_TYPE = {
    VIDEO: 'video',
    FILE: 'file',
    IMAGE: 'image',
}

export const TRAVEL_DOCUMENTATION_TYPE = {
    NATIONAL_ID: 1,
    PASSPORT: 2,
    SEAMANS_BOOK: 3,
}

export const PRIMARY_TRAINING_TYPE = {
    DIVE_SCHOOL_TRAINING: 1,
    OTHER_TRAINING: 2,
}

export const PRIMARY_TRAINING_TYPE_CATEGORY = {
    COMMERCIAL: 1,
    MILITARY: 2,
    OTHER: 3,
}

export const BANNER_TYPE = {
    PROJECT: 'project',
    HOMEPAGE: 'homepage',
}

export const PROJECT_POSITION_ACTIONS = {
    APPLY: 'apply',
    REAPPLY: 'reapply',
    WITHDRAW: 'withdraw',
    ACCEPT: 'accept',
    REJECT: 'reject',
    APPROVED_INVITE: 'approved_invite',
    DECLINED_INVITE: 'declined_invite',
    CANCELED_INVITE: 'canceled_invite',
    PERSON_EMPLOYED: 'person_employed',
}

export const PROJECT_WORKER_STATUS = {
    EMPLOYED: 1,
    APPLICANT: 2,
    INVITED: 3,
    CANDIDATES: 4,
}

export const DIVE_RECORD_STATUS = {
    DRAFT: {
        id: 1,
        name: 'Draft',
    },
    SUBMITTED: {
        id: 2,
        name: 'Submitted',
    },
    VERIFIED: {
        id: 3,
        name: 'Verified',
    },
    REJECTED: {
        id: 4,
        name: 'Rejected',
    },
    COMPLETED: {
        id: 5,
        name: 'Completed',
    },
    RE_SUBMITTED: {
        id: 6,
        name: 'Re-submitted',
    },
}

export const COLORS_NAMES = {
    RED: 'red',
    GREEN: 'green',
    GRAY: 'gray',
    BLUE: 'blue',
    GRAY_LIGHT: 'grayLight ',
}

export const AVAILABILITY = {
    AVAILABLE_FOR_HIRE: 'AVAILABLE_FOR_HIRE',
    UNAVAILABLE: 'CURRENTLY_UNAVAILABLE',
    EMPLOYED_FULL_TIME: 'EMPLOYED_FULL_TIME',
    NO_STATUS: 'NO_STATUS',
    CONTRACTED_FREELANCER: 'CONTRACTED_FREELANCER',
}

export const DECOMPRESSION_TYPES = {
    SURFACE_DECOMPRESSION: {
        id: 3,
    },
    // more exist in DB but are not used
}

export const BREATHING_APPARATUS_TYPES = {
    RECLAIM_HELMET: {
        id: 7,
        name: 'Reclaim Helmet',
        entityType: ENTITIES.BREATHING_APPARATUS_TYPE,
    },
    CCR: {
        id: 2,
        name: 'Closed circuit re-breather (CCR)',
        entityType: ENTITIES.BREATHING_APPARATUS_TYPE,
    },
    // more exist in DB but are not used
}

export const DIVE_MODES = {
    SCUBA_CONVENTIONAL: {
        id: 1,
    },
    SURFACE_SUPPLIED: {
        id: 2,
    },
    CLOSED_BELL_SATURATION: {
        id: 3,
    },
    RE_BREATHER: {
        id: 4,
    },
    SUPPLIED_WET_BELL: {
        id: 5,
    },
    CLOSED_BELL_BOUNCE: {
        id: 6,
    },
    ATMOSPHERIC_DIVING_SUIT: {
        id: 7,
    },
    CHAMBER_PRESSURIZATION_ONLY: {
        id: 8,
    },
}

export const GAS_CYLINDER_PURPOSES = {
    PRIMARY_BREATHING_SOURCE: {
        id: 1,
    },
    SECONDARY_BREATHING_SOURCE: {
        id: 2,
    },
    DECOMPRESSION_MIX: {
        id: 3,
    },
    DILUENT: {
        id: 4,
    },
    BAILOUT_CYLINDER: {
        id: 5,
    },
}

export const INFO_VALUE_TYPE = {
    INFO_TYPE: 'info_type',
    CONTRACTOR_LINK: 'contractor_link',
    DIVER_PROFILE_LINK: 'diver_profile_link',
    CERTIFICATE_URL: 'certificate_url',
    SIGNATURE: 'signature',
}

export const ACCESS_CONTROL_STATUSES = {
    NOT_FOUND: { id: 0 },
    NEW: { id: 1, name: 'New', color: 'blue' },
    REJECTED: { id: 2, name: 'Rejected', color: 'red' },
    GRANTED_WITHOUT_FILES: {
        id: 3,
        name: 'Access without files',
        color: 'orange',
    },
    GRANTED_WITH_FILES: { id: 4, name: 'Access with files', color: 'green' },
    GRANTED: { id: 5, name: 'Granted', color: 'green' },
    REQUESTED_AGAIN: { id: 6, name: 'Requested again', color: 'blue' },
    PROTECTED_PROPERTIES: { id: 7, name: 'Protected properties', color: 'red' },
}

export const ACCESS_CONTROL_MODULES = {
    MEDICAL_INFORMATION: 1,
    TRAVEL_DOCUMENTS: 2,
    TRAINING_RECORDS: 3,
    PERSONAL_EQUIPMENT: 4,
    PAST_EMPLOYMENT: 5,
    DIVE_RECORDS: 6,
    USER_CV: 7,
    PROJECT: 8,
    CONTACT_INFO: 9,
}

export const TOOLTIP_POSITION = {
    RIGHT: 'right',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
}

export const CHECKBOX = {
    DEFAULT: '-default',
    CHECKED: '-checked',
}

export const NOTIFICATIONS_SETTINGS_MODULES = {
    DATA_ACCESS_CONTROL: 1,
    DIVE_RECORD_VERIFICATIONS: 2,
    JOB_APPLICATIONS: 3,
    JOB_APPLICATION_STATUSES: 4,
    RELEASE_UPDATES: 5,
}

export const NOTIFICATION_TYPES = {
    IN_APP: 1,
    EMAIL: 2,
    PUSH: 3,
}

export const MODAL_BUTTONS = {
    PREV_BTN: 'prev_btn',
    SKIP_BTN: 'skip_btn',
    NEXT_BTN: 'next_btn',
}

export const PROJECT_STATUS = {
    PLANNING: 'PLANNING',
    MOBILIZING_PREPARING: 'MOBILIZING_PREPARING',
    IN_PROGRESS_EXECUTING: 'IN_PROGRESS_EXECUTING',
    COMPLETED: 'COMPLETED',
}

export const DIVE_PHYSICAL_CHARACTERISTIC = {
    OPEN_WATER: 'OPEN_WATER',
    RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
    OVERHEAD_OBSTRUCTION: 'OVERHEAD_OBSTRUCTION',
    CONFINED_LOCATION: 'CONFINED_LOCATION',
    BELOW_ICE_SHEET: 'BELOW_ICE_SHEET',
    SUBMERGED_HABITAT_ENTRY: 'SUBMERGED_HABITAT_ENTRY',
    SUBMERGED_COFFERDAM_ENTRY: 'SUBMERGED_COFFERDAM_ENTRY',
    SUBMERGED_PIPE_ENTRY: 'SUBMERGED_PIPE_ENTRY',
    SUBMERGED_TUNNEL_ENTRY: 'SUBMERGED_TUNNEL_ENTRY',
    SUBMERGED_VESSEL_ENTRY: 'SUBMERGED_VESSEL_ENTRY',
    LENGTH_DISTANCE_OF_PENETRATION: 'LENGTH_DISTANCE_OF_PENETRATION',
}

export const USER_SUBSCRIPTION_TYPE = {
    FREE: 1,
    PRO: 2,
    ENTERPRISE: 3,
}

export const PACKAGE_INFO_DATA = [
    {
        key: 'free',
        id: USER_SUBSCRIPTION_TYPE.FREE,
        icon: ICONS.CHECKMARK_ICON,
        iconColor: COLORS.GREEN,
        description: 'general.alwaysFree',
        active: true,
    },
    {
        key: 'pro',
        id: USER_SUBSCRIPTION_TYPE.PRO,
        icon: ICONS.PRO,
        iconColor: COLORS.YELLOW,
        description: 'general.comingSoon',
        buttonLabel: 'button.buyPro',
    },
    {
        key: 'enterprise',
        id: USER_SUBSCRIPTION_TYPE.ENTERPRISE,
        icon: ICONS.PRO,
        iconColor: COLORS.YELLOW,
        description: 'general.comingSoon',
        buttonLabel: 'button.buyEnterprise',
    },
]

export const COMPANY_TYPE = {
    DIVING_COMPANY: 1,
    CLIENT_COMPANY: 2,
}

export const COMPANY_USER_STATUS = {
    NEW: { value: 1, name: 'New', color: 'blue' },
    ACTIVE: { value: 2, name: 'Active', color: 'green' },
    BLOCKED: { value: 3, name: 'Blocked', color: 'red' },
    REJECTED: { value: 4, name: 'Rejected', color: 'red' },
}

export const ACTIVE_INACTIVE_STATUSES = {
    ACTIVE: { value: true, name: 'Active', color: 'green' },
    INACTIVE: { value: false, name: 'Inactive', color: 'red' },
}

export const CALL_CENTER_STATUSES = {
    SUCCESS_STATUS: { id: 130, name: 'Call successful', color: 'green' },
    INCOMPLETE_STATUS: { id: 110, name: 'Call Unsuccessful', color: 'red' },
    COMPLETED_STATUS: { id: 100, name: 'Completed', color: 'green' },
}

export const TERMS_AND_CONDITIONS_STATUSES = {
    ACCEPTED: { value: true, name: 'Accepted', color: 'green' },
    DECLINED: { value: false, name: 'Declined', color: 'red' },
}
