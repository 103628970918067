import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ALERT_TYPES,
    COMPANY_USER_STATUS,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import useFetchData from 'hooks/useFetchData'

import { editEntityService } from 'services/entity.service'

import Button from 'components/Button'
import { AvatarIcon } from 'components/icons'
import CardFooter from 'components/card/CardFooter'

const CompanyInvite = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const { fetchCurrentUser, fetchCompanyData } =
        useContext(CurrentUserContext)

    const {
        data: companyInvites,
        fetchData: getCompanyInvites,
        isReady: haveCompanyInvitesLoaded,
    } = useFetchData(ENTITIES.COMPANY_USERS, {
        status: COMPANY_USER_STATUS.NEW.value,
        include: 'company',
    })

    const goToCompany = (item) => {
        navigate(
            `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${item.company.profileHash}`
        )
    }

    const acceptInvite = async (item) => {
        await editStatus(item, COMPANY_USER_STATUS.ACTIVE.value, false)
    }

    const rejectInvite = async (item) => {
        await editStatus(item, COMPANY_USER_STATUS.REJECTED.value, true)
    }

    const editStatus = async (item, status, isRejection) => {
        try {
            await editEntityService(
                ENTITIES.COMPANY_USERS,
                item.id,
                {
                    status: status,
                },
                true
            )

            isRejection && (await getCompanyInvites())
            await fetchCurrentUser()
            await fetchCompanyData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (!haveCompanyInvitesLoaded) {
        return <></>
    }

    return (
        <>
            {companyInvites.map((item) => (
                <div className="m-boxes__white -mb20" key={item.id}>
                    <div className="column content-center">
                        <div
                            className="column aligned-center pointer -gap10 -mb10"
                            onClick={() => goToCompany(item)}
                        >
                            <AvatarIcon
                                avatarPath={item.company.logoPath}
                                icon={ICONS.BUILDING}
                            />
                            <span className="a-bodyTextMedium -title">
                                {item.company.name}
                            </span>
                        </div>
                        <span className="a-mediumText a-lightText">
                            {t('general.invitedToJoinAsAnEmployee')}
                        </span>
                    </div>
                    <CardFooter withBorder>
                        <div className="a-positionCard__button">
                            <Button
                                type={BUTTON_TYPE.BUTTON}
                                btnClass={BUTTON_STATUS.SECONDARY}
                                label="button.reject"
                                onClick={() => rejectInvite(item)}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                            />
                        </div>
                        <div className="a-positionCard__button">
                            <Button
                                type={BUTTON_TYPE.BUTTON}
                                btnClass={BUTTON_STATUS.PRIMARY}
                                label="button.accept"
                                onClick={() => acceptInvite(item)}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                            />
                        </div>
                    </CardFooter>
                </div>
            ))}
        </>
    )
}

export default CompanyInvite
